@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');

/* Range slider */
:root {
    --range-thumb-size: 36px;

    /* Colors */
    --primary_color: #E2136E;
    --secondary_color: #fff;
    --asset_color: #000;
}

body {
    font-family: 'Inter', sans-serif !important;
}

input[type=range] {
    appearance: none;
    background: #ccc;
    border-radius: 3px;
    height: 6px;
    margin-top: (--range-thumb-size - 6px) * 0.5;
    margin-bottom: (--range-thumb-size - 6px) * 0.5;
    --thumb-size: #{--range-thumb-size};
}

input[type=range]::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    background-color: #000;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: --range-thumb-size;
    width: --range-thumb-size;
}

input[type=range]::-moz-range-thumb {
    background-color: #000;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: --range-thumb-size;
    width: --range-thumb-size;
}

input[type=range]::-ms-thumb {
    background-color: #000;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: --range-thumb-size;
    width: --range-thumb-size;
}

input[type=range]::-moz-focus-outer {
    border: 0;
}

.bg_bkash {
    background: #E2136E !important;
}

.text_bkash {
    color: #E2136E !important;
}

.active_menu {
    background: #E2136E !important;
    transition: all 0.3s ease-in-out;
}

.border_bkash {
    border: 10px solid #E2136E !important;
}

.customInputClass {
    transition: all 150ms ease-in-out !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.01), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    border: 1px solid #dbdbdb !important;
}

.customInputClass:focus {
    outline: none !important;
    box-shadow: 0 0 0 0.2rem rgb(0, 123, 255) !important;
}

.flag-dropdown {
    border: 1px solid #dbdbdb !important;
}

.animate-spin {
    margin-right: 7px !important;
    top: 2px !important;
    animation: spin 1s infinite linear !important;
}

.opt_container {
    width: fit-content !important;
    margin: 0 auto !important;
}

.opt_container input {
    border: 1px solid #dbdbdb !important;
    border-radius: 5px !important;
    cursor: text !important;
    padding: 5px !important;
}

.opt_container input:focus {
    outline: none !important;
    border: 1px solid rgb(0, 123, 255) !important;
}

.handle_top_padding {
    padding-top: 0 !important;
}

.height_handle {
    min-height: calc(100vh - 150px) !important;
}

.custom_border {
    border-bottom: 1px solid #dbdbdb !important;
}

.custom_width {
    width: 20%;
}

.otp_wrapper {
    width: 100% !important;
}

@media only screen and (max-width: 384px) {
    .otp_width {
        width: 100% !important;
    }

    /* .opt_container {
        width: 100% !important;
        margin-left: 30% !important;
    }

    .opt_container input {
        padding: 0;
        width: 25px !important;
        height: 25px !important;
        margin-right: 10px !important;
    } */
}

::-webkit-file-upload-button {
    display: none;
}

/* Popup */
.popup_wrapper {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup_content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 50%;
    max-height: 500px;
    overflow-y: auto;
}

@media screen and (max-width: 768px) {
    .popup_content {
        width: 90%;
    }

}

.main_bg {
    background: rgb(241, 245, 249) !important;
}


/* Typography */
/* Typography */
:root {
    --text-4xl: 2.25rem;
    --text-3xl: 1.875rem;
    --text-2xl: 1.5rem;
    --text-xl: 1.25rem;
    --text-lg: 1.125rem;
    --text-base: 1rem;
    --text-sm: 0.875rem;
    --text-xs: 0.75rem;
}

.h1 {
    font-size: var(--text-4xl);
    font-weight: 800;
    letter-spacing: -0.025em;
}

.h2 {
    font-size: var(--text-3xl);
    font-weight: 800;
    letter-spacing: -0.025em;
}

.h3 {
    font-size: var(--text-3xl);
    font-weight: 800;
}

.h4 {
    font-size: var(--text-2xl);
    font-weight: 800;
    letter-spacing: -0.0125em;
}

@media (min-width: 768px) {
    .h1 {
        font-size: var(--text-5xl);
    }

    .h2 {
        font-size: var(--text-4xl);
    }
}

/* Buttons */
.btn,
.btn-lg,
.btn-sm,
.btn-xs {
    font-weight: 500;
    font-size: var(--text-sm);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    line-height: 1.25;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.15s ease-in-out;
}

.btn {
    padding: 0.5rem 0.75rem;
}

.btn-lg {
    padding: 0.75rem 1rem;
}

.btn-sm {
    padding: 0.25rem 0.5rem;
}

.btn-xs {
    padding: 0.125rem 0.5rem;
}

/* Forms */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
    font-size: var(--text-sm);
    color: #374151;
    background-color: #fff;
    border: 1px solid;
    border-color: #d1d5db;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
    border-radius: 0.25rem;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
    line-height: 1.25;
    padding: 0.5rem 0.75rem;
    border-color: #d1d5db;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.form-input,
.form-textarea {
    color: #9CA3AF;
}

.form-select {
    padding-right: 2.5rem;
}

.form-checkbox,
.form-radio {
    color: #3B82F6;
    border-color: #d1d5db;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge /
    scrollbar-width: none;
    / Firefox */
}

/* Other */
.bg-gradient-to-tr {
    background: linear-gradient(to top right, var(--tw-gradient-stops));
}

.bg-gradient-to-tl {
    background: linear-gradient(to top left, var(--tw-gradient-stops));
}

.bg-gradient-to-br {
    background: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.bg-gradient-to-bl {
    background: linear-gradient(to bottom left, var(--tw-gradient-stops));
}

.bg-gradient-to-t {
    background: linear-gradient(to top, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
    background: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-b {
    background: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-l {
    background: linear-gradient(to left, var(--tw-gradient-stops));
}

.gradient-stop {
    @apply transition-colors duration-300 ease-in-out;
}

.gradient-stop-hover:hover {
    @apply text-indigo-500;
}

.gradient-stop-active:active,
.gradient-stop-active:focus {
    @apply text-indigo-600;
}

.gradient-stop-primary {
    @apply text-indigo-500;
}

.gradient-stop-secondary {
    @apply text-gray-700;
}

.gradient-stop-success {
    @apply text-green-500;
}

.gradient-stop-warning {
    @apply text-yellow-500;
}

.gradient-stop-danger {
    @apply text-red-500;
}